.login {
    position: fixed;
    inset: 0;
    text-align: center;
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    gap: 2rem;
    padding: 1rem;
    grid-template-rows: 1fr 1fr 0.5fr;
}

.loginContent {
    text-align: center;
    display: grid;
    gap: 1rem;
    align-self: start;
    justify-items: center;
}

.signingIn {
    position: fixed;
    inset: 0;
    display: grid;
    align-content: center;
    justify-items: center;
    gap: 2rem;
}

.termsText {
    display: flex;
    align-items: center;
    text-align: start;
}

.links {
    display: flex;
    gap: 2rem;
}