.container {
    display: grid;
    grid-template-rows: min-content min-content 1fr 3.5rem;
    height: 100%;
    align-content: start;
}

.events {
    display: grid;
    justify-items: center;
    gap: 1rem;
    overflow-y: auto;
    grid-auto-rows: min-content;
    padding: 1rem;
}

.content {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}