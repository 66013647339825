.container {
    display: grid;
    grid-template-rows: min-content 1fr;
    height: 100%;
    align-content: start;
    min-height: 80vh;
}

.selectLocation {
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    height: 100%;
    align-content: start;
    min-height: 80vh;
}

.fields {
    display: grid;
    gap: 2rem;
}

.search {
    padding: 1rem;
}

.location {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    min-height: 3rem;
}

.locationName {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}