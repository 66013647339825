.container {
    display: grid;
    grid-auto-flow: row;
    height: 100%;
    align-content: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
    gap: 2rem;
    min-height: 80vh;
}

.content {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.details {
    display: grid;
    gap: 1rem;
    padding: 1rem 0;
}

.svg {
    filter: var(--svg-filter);
    width: 300px;
    max-width: 90%;
    padding: 1rem;
}