.settings {
    display: grid;
    grid-template-rows: min-content 1fr 3.5rem;
    height: 100%;
}

.settingsContainer {
    display: grid;
    align-items: center;
    gap: 1rem;
    overflow-y: auto;
    justify-self: center;
}

.settingsContent {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
}

.content {
    display: grid;
    gap: 1rem;
    justify-items: center;
}