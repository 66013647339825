.container {
    display: grid;
    grid-template-rows: min-content 25%;
    height: 100%;
    align-content: start;
    min-height: 80vh;
}

.dialogContainer {
    min-height: 100vh;
    max-height: 100vh;
}

.content {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.details {
    display: grid;
    gap: 1rem;
    padding: 1rem 0;
}