.map {
    display: grid;
    grid-template-rows: min-content 1fr 3.5rem;
    height: 100%;
}

.marker {
    background-color: white;
    border: none;
    border-radius: 50%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem !important;
    height: 1rem !important;
    margin-top: -1rem !important;
    margin-left: -1rem !important;
    box-shadow: 0 0 5px 2px #0008;
}

.myLocation {
    border: 4px solid white;
    border-radius: 50%;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.5rem;
    height: 0.5rem !important;
    margin-top: -0.5rem !important;
    margin-left: -0.5rem !important;
    box-shadow: 0 0 5px 2px #0008;
}

.search {
    display: grid;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0;
    grid-template-columns: min-content 1fr 1fr 1fr;
}

:global(.leaflet-tile) {
    filter: var(--map-tiles-filter);
    height: 256.5px !important;
    width: 256.5px !important;
}